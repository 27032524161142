import { ROUTER_ADDRESS } from "config/constants/exchange";
import { useEffect, useState } from "react";
import { getContract } from "utils/contractHelpers";
import IPancakeRouter02ABI from 'config/abi/IPancakeRouter02.json'
import miningABI from 'config/abi//mining.json'
import { ChainId, FACTORY_ADDRESS_MAP } from "@pancakeswap/sdk";
import FactoryABI from 'config/abi/Factory.json'
import { provider } from 'utils/wagmi'

export default function useTokenPrice() {
    
    const [stats, setStats] = useState({
        totalTx: 0,
        totalPair: 0,
        Marketcap: 0,
        tokenprice: 0
    })

    useEffect(() => {
        async function fetch() {
            try {
                const miningContract = getContract(miningABI, "0xe00179872575edb855ce5fa258c76661c54f0495", provider(ChainId.BSC));
                const miningContract2 = getContract(miningABI, "0x872330798840d744b0935dc68541e1a23343a0f0", provider(ChainId.BSC));
                const userCount = await miningContract.totalUsers();
                const userCount2 = await miningContract2.totalUsers();
                const routerContract = getContract(IPancakeRouter02ABI, ROUTER_ADDRESS[ChainId.BSC], provider(ChainId.BSC));
                const totalTx = await routerContract.totalTx();
                const factoryContract = getContract(FactoryABI, FACTORY_ADDRESS_MAP[ChainId.BSC], provider(ChainId.BSC));
                const totalPair = await factoryContract.allPairsLength();

                const oldPrice = Number(parseFloat(userCount) * Number(0.00025)).toFixed(5);
                const newPrice = Number(parseFloat(userCount2) * Number(0.0025)).toFixed(5);
                const totalMGPPrice = parseFloat(oldPrice) + parseFloat(newPrice) + Number(35);
                const totalUser = Number(userCount) +  Number(userCount2);
            
                

                setStats({
                    totalTx,
                    totalPair,
                    Marketcap: totalUser,
                    tokenprice : totalMGPPrice
                })
            }
            catch (err) {
                console.log(err.message)
            }

        }

        fetch()

    }, [])

    return stats;

}